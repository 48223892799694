@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

::-webkit-scrollbar {
  width: 8px;
}
  
::-webkit-scrollbar-track {
  background: linear-gradient(#0694C6, #9E1F63);
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background: var(--chakra-colors-whiteAlpha-600);
  border-radius: 24px;
}